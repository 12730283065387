




























































.paginate {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: relative;
  z-index: 20;
  .paginate {
    background: rgba($color: #9d9898, $alpha: 0.05);
    border-radius: 8px;
    width: 32px;
    height: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #2a2e4a;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    border: 1px solid #2a2e4a60;
    margin: 5px;
    &.next {
      background: #f2f2f2;
      color: #2a2e4a;
    }
    &.prev {
      background: #f2f2f2;
      color: #2a2e4a;
    }
    &.active {
      background: #009750;
      color: #ffffff;
    }
  }
}
